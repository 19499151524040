import React, { Fragment, useState } from "react";
import uuid from 'uuid/dist/v4';
import PropTypes from 'prop-types';


const Formulario = ({crearCita}) => {
  //Crear state de citas
  const [cita, actualizarCita] = useState({
    mascota: "", //es un string vacio por que desde el inicio no tenemos datos hasta q el usuario escriba
    propietario: "", //ahi se llenara el objeto
    fecha: "",
    hora: "",
    sintomas: "",
  }); // {} = objeto creacion de un objeto para agregar todos los campos que se tienen
  //// cita va atener toda la informacion del state y la funcion que reescribe este state

  //segundo state 
  const [error, actualizarError] = useState (false);   //este no pasa un objeto pero pasa un booleano en cambio el const cita pasa un objeto


  //definicion de la funcion actualizarState que se ejecuta cada vez que el usuario escribe en un input
  const actualizarState = e => {  // e de vento cada vez que cambia se pasa el evento 
      //extraer lo que el usuarioo escribe 
      //console.log(e.target.name);  /// si quieres saber en que capo esta escribiendo puedes poner
      //tenemos q mapear los campos para que no se revuelva la informacion 
      //console.log(e.target.value);  /// conseguir lo q el usuario escribe e.target.value
    actualizarCita({
        ...cita, //copia de la cita 
        [e.target.name]: e.target.value   ///e.target.name es como tener  [mascota] : e.target.value
    })  // funcion que modifica el state
  }

  //extraer los valores
  const {mascota, propietario, fecha, hora, sintomas} = cita; //distrotorin sirve para no tener q escribir  cita.mascota cita.propietario etx ...

  //Cunado el usuario precione agreagr cita
  const submitCita = e => {   //cuando un arrowfunction solo tiene un parametro en este caso un evento podemos quitar los parenytecis
       e.preventDefault();

       //console.log(mascota);



       //Validar siempre primero 
       if(mascota.trim() === '' || propietario.trim() === '' || fecha.trim() === '' || hora.trim() === '' || sintomas.trim() === '' ){
           actualizarError(true);
           return; // para que no se ejecute nada mas despues 
       }
       //eliminar el mensaje previo
       actualizarError(false); //elimina el mensaje previo de se necesitan los campos 

       //asignar un ID
       // asignar un id intslando una libreria que se llama uuid con npm i uuid genera un id bastante grande
       //se utiliza la v4 
       cita.id = uuid();
       //console.log(cita);

       //Crear la cita
       crearCita(cita);

       //Reiniciar el form  
       actualizarCita({  // reinicia cada una de las propiedades siempre con el modificador del state 
        mascota: "",        /// se reinicia por que abajo tiene los values  y se tiene asiganas las variables de cita en los values detecta que es una string vacio 
        propietario: "",
        fecha: "",
        hora: "",
        sintomas: "",
    })

  }

  return (
    <Fragment>
      <h2>Crear Cita</h2>

        { error ? <p className="alerta-error "> Todos los campos son obligatorios</p>  :null}    

      <form 
            onSubmit={submitCita}
      >
        <label>Nombre Mascota</label>
        <input
          type="text"
          name="mascota"
          className="u-full-width"
          placeholder="Nombre Mascota"
          onChange={actualizarState} //agregaremos una fucion que se llamara actualizarstate
          value={mascota}
        />

        <label>Nombre del Dueno</label>
        <input
          type="text"
          name="propietario"
          className="u-full-width"
          placeholder="Nombre dueno"
          onChange={actualizarState}
          value={propietario}  ///nos permitira mas adelante resetiar el formulario
        />

        <label>Fecha</label>
        <input type="date" name="fecha" className="u-full-width"  onChange={actualizarState}  value={fecha}/>

        <label>Hora</label>
        <input type="time" name="hora" className="u-full-width"  onChange={actualizarState} value={hora} />

        <label>Sintomas de la mascota</label>
        <textarea name="sintomas" className="u-full-width"  onChange={actualizarState} value={sintomas}></textarea>

        <button type="submit" className="u-full-width button-primary">
          Agregar Citas
        </button>
      </form>
    </Fragment>
  );
};

Formulario.propTypes = {
   crearCita : PropTypes.func.isRequired
}

export default Formulario;
