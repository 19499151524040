import React, { Fragment, useState, useEffect } from "react";  //agregamos un hook para agregar las citas en local storage como un bd sin bd no se van a perder los cambio cunado recarguemos (hook : useEffect)
import Formulario from './components/Formulario';
import Cita from './components/Cita';

function App() {

  //citas en local storage 
  let citasIniciales = JSON.parse(localStorage.getItem('citas'));  //local storasge solo almacena strings por lo que vamos agregar json.parse por que tenemos un arreglo jeyson punto varse va a convertir ese arreglo dentro de un string para q pued ser manipulado  
  if(!citasIniciales){
    citasIniciales = [];
  }

  //arreglo de citas de todas las citas  
  const [citas, guardarCitas] = useState(citasIniciales);  // iniciarlo como un arreglo vacio 
  

   //Use Effect para realizar ciertas operaciones caundo el state cambia 
   useEffect( () => {
      if(citasIniciales){
         localStorage.setItem('citas', JSON.stringify(citas));   //pasamos las citas que estan en el state o localstorage
      }else{
         localStorage.setItem('citas', JSON.stringify([]));
      }
   }, [citas,citasIniciales]);   //solo para que useeffect hay que pasarle un arreglo vacio (array de dependecias) y asi va a estar pendiente de todo lo que pase en citas

   //local estorage es una base de datos que solo almacena strings 

   //funcion que tolas las citas y agrega la nueva 
   const crearCita =  cita => {
        guardarCitas([
          ...citas,
          cita
        ]);
   }

   //Funcion que elimina una cita por su id
   const eliminarCita = id => {
     //console.log(id);
     const nuevasCitas = citas.filter(cita => cita.id !== id );   //esto va iterar en todas las citas igual q un foreach 
     guardarCitas(nuevasCitas);  // como te crea un arreglo nuevo no nceseitas [] los corchetes
   } 

   //mensaje condicional 
   //console.log(citas.length);
   const titulo = citas.length === 0 ? 'no hay citas'  :  'admintra tus citas ';

  return (
    <Fragment>
      <h1>Administrador de Pacientes</h1>
        <div className="container">
          <div className="row">
            <div className="one-half column">
            <Formulario 
                crearCita = {crearCita}
            />
            </div>
            <div className="one-half column">
                <h2>
                   {titulo}    {/* imprimimos la variable que queda del if */}                   
                </h2>
                {/* iteramos nuestro arreglo de citas recorriendolo con un map se utiliza mas el map quel foreach */}
                {citas.map(cita => (
                  <Cita 
                    key =  {cita.id}     // siempre que se itera se para un key iterar = que recorrer 
                    cita = {cita}       // de aqui se pasa cita para el otro lado  el componente cita     
                    eliminarCita = {eliminarCita} //le pasamos la funcion      
                  />
                ))}
            </div>
          </div>
        </div>
    </Fragment>       
  );
}

export default App;
